import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import ChatbotApi from "../api/chatbotApi";
import MemberApi from "../api/memberApi";
import DocumentApi from "../api/documentApi";
import {updateSocketIOChatbotId} from "../socketio/socketIoClient";
import AppConfig from "../general/constant/appConfig";
import ChatbotIndicatorApi from "../api/chatbotIndicatorApi";
import {BOT_SYSTEM_STATUS} from "../general/constant/appConstant";

const initialState = {
    selectedMember: {
        account: {
            email: null,
            accountId: null,
            fullname: null
        }
    },
    selectedDocument: {
        documentId: null,
        documentName: null,
    },
    chatbot: {
        chatbotId: null,
        walletId: null,
        chatbotName: null,
        prompt: null,
        chabotHonorific: null,
        customerHonorific: null,
        avatar: null,
        test: null,
        documents: [],
        scriptSource: null,
        members: [],
        subscription: null,
        statisticMessage: null,
        statisticFinancial: null,
        historySubscription: [],
    },
    kPIs: [],
    chatbots: [],
    chatbotIndicator: {
        wallFacebookCustomer: 0,
        cMSCustomer: 0,
        cMSAgentMessage: 0,
        websiteComment: 0,
        scenarioBuilding: 0,
        newScenario: 0,
        situation: 0,
        modifyScenario: 0,
        modifiedSituation: 0,
        testSituation: 0,
        appSystem: BOT_SYSTEM_STATUS.OK,
        webSystem: BOT_SYSTEM_STATUS.OK,
        facebookSystem: BOT_SYSTEM_STATUS.OK
    }
}

export const thunkGetChabot = createAsyncThunk("chatbot/information", async (params, thunkAPI) => {
    return ChatbotApi.getInformation(params);
})

export const thunkAddChabot = createAsyncThunk("chatbot/add", async (params, thunkAPI) => {
    return ChatbotApi.addChatbot(params);
})

export const thunkUpdateChabot = createAsyncThunk("chatbot/update", async (params, thunkAPI) => {
    return ChatbotApi.updateChatbot(params);
})

export const thunkDeleteChabot = createAsyncThunk("chatbot/delete", async (params, thunkAPI) => {
    return ChatbotApi.deleteChatbots(params);
})

export const thunkInviteMember = createAsyncThunk("chatbot/inviteMember", async (params, thunkAPI) => {
    return MemberApi.invite(params);
})

export const thunkChangeRoleMember = createAsyncThunk("chatbot/changeRoleMember", async (params, thunkAPI) => {
    return MemberApi.changeRole(params);
})

export const thunkLockMember = createAsyncThunk("chatbot/lockMember", async (params, thunkAPI) => {
    return MemberApi.lock(params);
})

export const thunkDeletingMember = createAsyncThunk("chatbot/deleteMember", async (params, thunkAPI) => {
    return MemberApi.delete(params);
})

export const thunkUploadDocument = createAsyncThunk("chatbot/uploadDocument", async (params, thunkAPI) => {
    return DocumentApi.upload(params)
})

export const thunkActiveDocument = createAsyncThunk("chatbot/activeDocument", async (params, thunkAPI) => {
    return DocumentApi.active(params)
})

export const thunkTestDocument = createAsyncThunk("chatbot/testDocument", async (params, thunkAPI) => {
    return DocumentApi.test(params)
})

export const thunkRenameDocument = createAsyncThunk("chatbot/renameDocument", async (params, thunkAPI) => {
    return DocumentApi.rename(params)
})

export const thunkDeleteDocument = createAsyncThunk("chatbot/deleteDocument", async (params, thunkAPI) => {
    return DocumentApi.delete(params)
})

export const thunkGetAllChatbots = createAsyncThunk("chatbot/getAllChatbot", async (params, thunkAPI) => {
    return ChatbotApi.getAll(params)
})

export const thunkSubscribePlan = createAsyncThunk("chatbot/subscribePlane", async (params, thunkAPI) => {
    return ChatbotApi.subscribePlan(params)
})

export const thunkGetKPI = createAsyncThunk("chatbot/getKPI", async (params, thunkAPI) => {
    return ChatbotApi.getKPI(params)
})

export const thunkGetChatbotIndicator = createAsyncThunk("report/getChatbotIndicator", (params, thunkAPI) => {
    return ChatbotIndicatorApi.getOrCreate(params)
})

export const thunkUpdateChatbotIndicator = createAsyncThunk("report/updateChatbotIndicator", (params, thunkAPI) => {
    return ChatbotIndicatorApi.update(params)
})

export const chatbotSlice = createSlice({
    name: "chatbot", initialState
    , reducers: {
        setSelectedMember: (state, action) => {
            const {payload} = action
            const {member} = payload
            state.selectedMember = member
        },
        setSelectedDocument: (state, action) => {
            const {payload} = action
            const {document} = payload
            state.selectedDocument = document
        },
    }, extraReducers: builder => {

        builder.addCase(REHYDRATE, (state, action) => {
            const {payload} = action;

            if (payload && payload.chatbot) {
                const {chatbot} = payload;
                state.selectedMember = chatbot.selectedMember;
                state.selectedDocument = chatbot.selectedDocument;
                state.chatbot = chatbot.chatbot;
                state.kPIs = chatbot.kPIs;
                state.chatbots = chatbot.chatbots;
                state.chatbotIndicator = chatbot.chatbotIndicator
            }

            state.isGettingChabot = false;
            state.isAddingChatbot = false;
            state.isUpdatingChatbot = false;
            state.isDeletingChatbot = false;
            state.isInvitingAccount = false;
            state.isChangingRoleAccount = false;
            state.isLockingAccount = false;
            state.isUploadingFile = false;
            state.isDeletingAccount = false;
            state.isActivatingDocument = false;
            state.isTestingDocument = false;
            state.isRenamingDocument = false;
            state.isDeletingDocument = false;
            state.isGettingAllChatbots = false;
            state.isSubscribingPlan = false;
            state.isGettingChatbotIndicator = false
            state.isUpdatingChatbotIndicator = false
        });


        builder.addCase(thunkGetChabot.pending, state => {
            state.isGettingChabot = true
        })
        builder.addCase(thunkGetChabot.rejected, (state, action) => {
            state.isGettingChabot = false
        })
        builder.addCase(thunkGetChabot.fulfilled, (state, action) => {
            const {payload} = action
            const {chatbot} = payload
            const {chatbotId} = chatbot
            updateSocketIOChatbotId(chatbotId)
            const {avatar,} = chatbot
            state.chatbot = {...chatbot, avatar: AppConfig.baseUrl + avatar}
            state.isGettingChabot = false
        })

        builder.addCase(thunkAddChabot.pending, state => {
            state.isAddingChatbot = true
        })
        builder.addCase(thunkAddChabot.rejected, (state, action) => {
            state.isAddingChatbot = false
        })
        builder.addCase(thunkAddChabot.fulfilled, (state, action) => {
            const {payload} = action
            const {member} = payload
            const {chatbot} = member
            const {avatar} = chatbot
            state.chatbot = {...chatbot, avatar: AppConfig.baseUrl + avatar}
            state.isAddingChatbot = false
        })

        builder.addCase(thunkDeleteChabot.pending, state => {
            state.isDeletingChatbot = true
        })
        builder.addCase(thunkDeleteChabot.rejected, (state, action) => {
            state.isDeletingChatbot = false
        })
        builder.addCase(thunkDeleteChabot.fulfilled, (state, action) => {
            const {payload} = action
            state.isDeletingChatbot = false
            state = {...initialState}
        })

        builder.addCase(thunkUpdateChabot.pending, state => {
            state.isUpdatingChatbot = true
        })
        builder.addCase(thunkUpdateChabot.rejected, (state, action) => {
            state.isUpdatingChatbot = false
        })
        builder.addCase(thunkUpdateChabot.fulfilled, (state, action) => {
            const {payload} = action
            const {chatbot} = payload
            const {avatar,} = chatbot
            state.chatbot = {...chatbot, avatar: AppConfig.baseUrl + avatar}
            state.isUpdatingChatbot = false
        })

        builder.addCase(thunkInviteMember.pending, state => {
            state.isInvitingAccount = true
        })
        builder.addCase(thunkInviteMember.rejected, (state, action) => {
            state.isInvitingAccount = false
        })
        builder.addCase(thunkInviteMember.fulfilled, (state, action) => {
            const {payload} = action
            const {member} = payload
            state.chatbot.members.push(member)
            state.isInvitingAccount = false
        })

        builder.addCase(thunkChangeRoleMember.pending, state => {
            state.isChangingRoleAccount = true
        })
        builder.addCase(thunkChangeRoleMember.rejected, (state, action) => {
            state.isChangingRoleAccount = false
        })
        builder.addCase(thunkChangeRoleMember.fulfilled, (state, action) => {
            const {payload} = action
            const {member} = payload
            const {memberId} = member
            const index = state.chatbot.members.findIndex((item) => item.memberId === memberId)
            state.chatbot.members[index] = member
            state.isChangingRoleAccount = false
        })

        builder.addCase(thunkLockMember.pending, state => {
            state.isLockingAccount = true
        })
        builder.addCase(thunkLockMember.rejected, (state, action) => {
            state.isLockingAccount = false
        })
        builder.addCase(thunkLockMember.fulfilled, (state, action) => {
            const {payload} = action
            const {member} = payload
            const {memberId} = member
            const index = state.chatbot.members.findIndex((item) => item.memberId === memberId)
            state.chatbot.members[index] = member
            state.isLockingAccount = false
        })

        builder.addCase(thunkDeletingMember.pending, state => {
            state.isDeletingAccount = true
        })
        builder.addCase(thunkDeletingMember.rejected, (state, action) => {
            state.isDeletingAccount = false
        })
        builder.addCase(thunkDeletingMember.fulfilled, (state, action) => {
            const {payload} = action
            const {member} = payload
            const {memberId} = member
            const index = state.chatbot.members.findIndex((item) => item.memberId === memberId)
            state.chatbot.members.splice(index, 1)
            state.isDeletingAccount = false
        })

        builder.addCase(thunkUploadDocument.pending, state => {
            state.isUploadingFile = true
        })
        builder.addCase(thunkUploadDocument.rejected, (state, action) => {
            state.isUploadingFile = false
        })
        builder.addCase(thunkUploadDocument.fulfilled, (state, action) => {
            const {payload} = action
            const {document} = payload
            state.chatbot.documents.unshift(document)
            state.isUploadingFile = false
        })

        builder.addCase(thunkActiveDocument.pending, state => {
            state.isActivatingDocument = true
        })
        builder.addCase(thunkActiveDocument.rejected, (state, action) => {
            state.isActivatingDocument = false
        })
        builder.addCase(thunkActiveDocument.fulfilled, (state, action) => {
            const {payload} = action
            const {document} = payload
            const documents = state.chatbot.documents
            state.chatbot.documents = documents.map((item) => {
                const {isActive, documentId, ...rest} = item
                if (documentId === document.documentId) {
                    return {isActive: true, documentId, ...rest}
                } else {
                    return {isActive: false, documentId, ...rest}
                }
            })
            state.isActivatingDocument = false
        })

        builder.addCase(thunkTestDocument.pending, state => {
            state.isTestingDocument = true
        })
        builder.addCase(thunkTestDocument.rejected, (state, action) => {
            state.isTestingDocument = false
        })
        builder.addCase(thunkTestDocument.fulfilled, (state, action) => {
            const {payload} = action
            const {document} = payload
            const documents = state.chatbot.documents
            state.chatbot.documents = documents.map((item) => {
                const {isTest, documentId, ...rest} = item
                if (documentId === document.documentId) {
                    return {isTest: true, documentId, ...rest}
                } else {
                    return {isTest: false, documentId, ...rest}
                }
            })
            state.isTestingDocument = false
        })

        builder.addCase(thunkRenameDocument.pending, state => {
            state.isRenamingDocument = true
        })
        builder.addCase(thunkRenameDocument.rejected, (state, action) => {
            state.isRenamingDocument = false
        })
        builder.addCase(thunkRenameDocument.fulfilled, (state, action) => {
            const {payload} = action
            const {document} = payload
            const {documentId} = document
            const index = state.chatbot.documents.findIndex((item) => item.documentId === documentId)
            state.chatbot.documents.splice(index, 1, document)
            state.isRenamingDocument = false
        })

        builder.addCase(thunkDeleteDocument.pending, state => {
            state.isDeletingDocument = true
        })
        builder.addCase(thunkDeleteDocument.rejected, (state, action) => {
            state.isDeletingDocument = false
        })
        builder.addCase(thunkDeleteDocument.fulfilled, (state, action) => {
            const {payload} = action
            const {documentIds} = payload
            for (const documentId of documentIds) {
                const index = state.chatbot.documents.findIndex((item) => item.documentId === documentId)
                state.chatbot.documents.splice(index, 1)
            }
            state.isDeletingDocument = false
        })

        builder.addCase(thunkGetAllChatbots.pending, state => {
            state.isGettingAllChatbots = true
        })
        builder.addCase(thunkGetAllChatbots.rejected, (state, action) => {
            state.isGettingAllChatbots = false
        })
        builder.addCase(thunkGetAllChatbots.fulfilled, (state, action) => {
            const {payload} = action
            const {chatbots} = payload
            state.chatbots = chatbots
            state.isGettingAllChatbots = false
        })

        builder.addCase(thunkSubscribePlan.pending, state => {
            state.isSubscribingPlan = true
        })
        builder.addCase(thunkSubscribePlan.rejected, (state, action) => {
            state.isSubscribingPlan = false
        })
        builder.addCase(thunkSubscribePlan.fulfilled, (state, action) => {
            const {payload} = action
            const {subscription} = payload
            state.chatbot.subscription = subscription
            state.isSubscribingPlan = false
        })

        builder.addCase(thunkGetKPI.fulfilled, (state, action) => {
            const {payload} = action
            const {kPIs} = payload
            state.kPIs = kPIs
        })

        builder.addCase(thunkGetChatbotIndicator.pending, state => {
            state.isGettingChatbotIndicator = true
        })
        builder.addCase(thunkGetChatbotIndicator.rejected, (state, action) => {
            state.isGettingChatbotIndicator = false
        })
        builder.addCase(thunkGetChatbotIndicator.fulfilled, (state, action) => {
            const {payload} = action
            const {chatbotIndicator} = payload
            state.chatbotIndicator = chatbotIndicator
            state.isGettingChatbotIndicator = false
        })

        builder.addCase(thunkUpdateChatbotIndicator.pending, state => {
            state.isUpdatingChatbotIndicator = true
        })
        builder.addCase(thunkUpdateChatbotIndicator.rejected, (state, action) => {
            state.isUpdatingChatbotIndicator = false
        })
        builder.addCase(thunkUpdateChatbotIndicator.fulfilled, (state, action) => {
            const {payload} = action
            const {chatbotIndicator} = payload
            state.chatbotIndicator = chatbotIndicator
            state.isUpdatingChatbotIndicator = false
        })

    }
})
export const {reducer, actions} = chatbotSlice

export const {setSelectedMember, setSelectedDocument} = actions

export default reducer